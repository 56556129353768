import './GiftEventTab.css'

import { PageHeader, Spin, Tabs, Tooltip, Badge } from 'antd'
import React, { useEffect } from 'react'

import EventChooser from './EventChooser/EventChooser'
import { changeEventItem } from '../../../actions/orderProcessAction'
import { connect } from 'react-redux'
import { setUpEventEdit } from '../../../actions/orderProcessAction'

const { TabPane } = Tabs

const getTitle = (id) =>
  id
    ? `${id?.onGoing ? '進行中的活動，內容：' : '非進行中活動：'}${
        id?.description
      }`
    : ''

const getBadge = (id) => (id?.onGoing ? <Badge color='#52c41a' /> : null)

const GiftEventTab = ({
  hook_event,
  stainless,
  glass_single_main,
  glass_single_two_one,
  cut,
  glass_comb_get_glove,
  straw_glove_get_box,
  setUpEventEdit,
  changeEventItem,
  tau_get_tau,
  tau_get_glass,
  glass_comb_give_tau_every,
  wash_get_storage,
  wan_wan_get_two,
  tau_n_get_n,
  glass_comb_tau_and_box,
  pandora,
  nano_sponge,
  cup_get_sticker,
  cup_get_bottom_tau,
  evey_tau_get_box,
  silicon_get_cut,
  jp_mask_box_get_mask_clip,
  random_three_aroma_clips,
  eight_clips_get_oil,
  four_glasses_get_two_brushes,
  two_get_smile_box,
  three_get_smile_box,
  dia_12mm_big_glove_get_silicon_straw,
  dia_14mm_big_glove_get_silicon_straw,
  small_glove_get_silicon_straw,
  glass_comb_extra,
  no_accumulate_four_glasses_get_two_brushes,
  four_glass_get_bag,
  four_glass_get_edge_tau,
  elephant_cup_get_tulip_cup_glove,
  loading,
  corr,
}) => {
  useEffect(() => {
    setUpEventEdit()
  }, [setUpEventEdit])
  return (
    <div className='gift-tab d-flex flex-column'>
      <PageHeader
        title='活動適用範圍編輯'
        subTitle='編輯每個活動所包含的商品選項'
      />

      {loading ? (
        <div className='tab-tab'>
          <Spin className='spin-page' size='large' />
        </div>
      ) : (
        <Tabs tabPosition='left' className='tab-tab' size='small'>
          <TabPane
            tab={
              <Tooltip title={getTitle(jp_mask_box_get_mask_clip)}>
                {getBadge(jp_mask_box_get_mask_clip)}
                {'日式口罩盒送口罩夾'}
              </Tooltip>
            }
            key='-1'
          >
            <EventChooser
              event={jp_mask_box_get_mask_clip}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(dia_12mm_big_glove_get_silicon_straw)}>
                {getBadge(dia_12mm_big_glove_get_silicon_straw)}
                {'12mm粗吸管套送矽膠吸管'}
              </Tooltip>
            }
            key='-1fsdfdfg'
          >
            <EventChooser
              event={dia_12mm_big_glove_get_silicon_straw}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(dia_14mm_big_glove_get_silicon_straw)}>
                {getBadge(dia_14mm_big_glove_get_silicon_straw)}
                {'14mm粗吸管套送矽膠吸管'}
              </Tooltip>
            }
            key='-1fsdsdsdfdfg'
          >
            <EventChooser
              event={dia_14mm_big_glove_get_silicon_straw}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(small_glove_get_silicon_straw)}>
                {getBadge(small_glove_get_silicon_straw)}
                {'8mm細吸管套送矽膠吸管'}
              </Tooltip>
            }
            key='-sdfsdf'
          >
            <EventChooser
              event={small_glove_get_silicon_straw}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(two_get_smile_box)}>
                {getBadge(two_get_smile_box)}
                {'買二送微笑'}
              </Tooltip>
            }
            key='-321'
          >
            <EventChooser
              event={two_get_smile_box}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(three_get_smile_box)}>
                {getBadge(three_get_smile_box)}
                {'買三送微笑'}
              </Tooltip>
            }
            key='-324f'
          >
            <EventChooser
              event={three_get_smile_box}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(random_three_aroma_clips)}>
                {getBadge(random_three_aroma_clips)}
                {'隨機三個香薰扣選項'}
              </Tooltip>
            }
            key='-11'
          >
            <EventChooser
              event={random_three_aroma_clips}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(eight_clips_get_oil)}>
                {getBadge(eight_clips_get_oil)}
                {'八個香薰扣送精油，每單最多一'}
              </Tooltip>
            }
            key='-12'
          >
            <EventChooser
              event={eight_clips_get_oil}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(four_glasses_get_two_brushes)}>
                {getBadge(four_glasses_get_two_brushes)}
                {'(可累計)四玻璃吸管送二刷'}
              </Tooltip>
            }
            key='-13'
          >
            <EventChooser
              event={four_glasses_get_two_brushes}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(four_glass_get_bag)}>
                {getBadge(four_glass_get_bag)}
                {'(無累計)買四個玻璃吸管送束口袋'}
              </Tooltip>
            }
            key='-1hj3'
          >
            <EventChooser
              event={four_glass_get_bag}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(four_glass_get_edge_tau)}>
                {getBadge(four_glass_get_edge_tau)}
                {'買四個玻璃吸管送防裂嘴套'}
              </Tooltip>
            }
            key='-1hjd3'
          >
            <EventChooser
              event={four_glass_get_edge_tau}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip
                title={getTitle(no_accumulate_four_glasses_get_two_brushes)}
              >
                {getBadge(no_accumulate_four_glasses_get_two_brushes)}
                {'(無累計)四玻璃吸管送二刷'}
              </Tooltip>
            }
            key='-13ee'
          >
            <EventChooser
              event={no_accumulate_four_glasses_get_two_brushes}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(elephant_cup_get_tulip_cup_glove)}>
                {getBadge(elephant_cup_get_tulip_cup_glove)}
                {'大象杯送鬱金香杯套「每單一」'}
              </Tooltip>
            }
            key='-13eee'
          >
            <EventChooser
              event={elephant_cup_get_tulip_cup_glove}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(cup_get_sticker)}>
                {getBadge(cup_get_sticker)}
                {'杯子送貼紙的活動'}
              </Tooltip>
            }
            key='0'
          >
            <EventChooser
              event={cup_get_sticker}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(cup_get_bottom_tau)}>
                {getBadge(cup_get_bottom_tau)}
                {'杯子送杯底套的活動'}
              </Tooltip>
            }
            key='0-1'
          >
            <EventChooser
              event={cup_get_bottom_tau}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(straw_glove_get_box)}>
                {getBadge(straw_glove_get_box)}
                {'吸管套送盒子的活動'}
              </Tooltip>
            }
            key='1'
          >
            <EventChooser
              event={straw_glove_get_box}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(evey_tau_get_box)}>
                {getBadge(evey_tau_get_box)}
                {'每個吸管套送對應盒子'}
              </Tooltip>
            }
            key='1-0'
          >
            <EventChooser
              event={evey_tau_get_box}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(tau_get_tau)}>
                {getBadge(tau_get_tau)}
                {'吸管套買一送一的活動'}
              </Tooltip>
            }
            key='1-1'
          >
            <EventChooser
              event={tau_get_tau}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(tau_get_glass)}>
                {getBadge(tau_get_glass)}
                {'吸管套送玻璃吸管'}
              </Tooltip>
            }
            key='1-3'
          >
            <EventChooser
              event={tau_get_glass}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(tau_n_get_n)}>
                {getBadge(tau_n_get_n)}
                {'吸管套買n送n適合的吸管'}
              </Tooltip>
            }
            key='1-4'
          >
            <EventChooser
              event={tau_n_get_n}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(wash_get_storage)}>
                {getBadge(wash_get_storage)}
                {'洗衣凝珠送收納'}
              </Tooltip>
            }
            key='wash_get_storage'
          >
            <EventChooser
              event={wash_get_storage}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(glass_comb_get_glove)}>
                {getBadge(glass_comb_get_glove)}
                {'玻璃組合送吸管套'}
              </Tooltip>
            }
            key='2'
          >
            <EventChooser
              event={glass_comb_get_glove}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(glass_comb_tau_and_box)}>
                {getBadge(glass_comb_tau_and_box)}
                {'玻璃組合送吸管套跟小方盒'}
              </Tooltip>
            }
            key='2-2'
          >
            <EventChooser
              event={glass_comb_tau_and_box}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(glass_comb_give_tau_every)}>
                {getBadge(glass_comb_give_tau_every)}
                {'每個選項玻璃組合送吸管套'}
              </Tooltip>
            }
            key='2-1'
          >
            <EventChooser
              event={glass_comb_give_tau_every}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(cut)}>
                {getBadge(cut)}
                {'切口活動'}
              </Tooltip>
            }
            key='3'
          >
            <EventChooser event={cut} corr={corr} change={changeEventItem} />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(silicon_get_cut)}>
                {getBadge(silicon_get_cut)}
                {'矽膠吸管贈送切口'}
              </Tooltip>
            }
            key='3-1-1'
          >
            <EventChooser
              event={silicon_get_cut}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(glass_comb_extra)}>
                {getBadge(glass_comb_extra)}
                {'玻璃吸管組合送護嘴套和收納盒'}
              </Tooltip>
            }
            key='3-1-2'
          >
            <EventChooser
              event={glass_comb_extra}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(glass_single_two_one)}>
                {getBadge(glass_single_two_one)}
                {'玻璃單根舊活動，買二送一'}
              </Tooltip>
            }
            key='4'
          >
            <EventChooser
              event={glass_single_two_one}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(pandora)}>
                {getBadge(pandora)}
                {'潘朵拉玻璃吸管送吸管套'}
              </Tooltip>
            }
            key='4-1-1'
          >
            <EventChooser
              event={pandora}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(wan_wan_get_two)}>
                {getBadge(wan_wan_get_two)}
                {'波霸彎彎吸管，買一送一'}
              </Tooltip>
            }
            key='4-1'
          >
            <EventChooser
              event={wan_wan_get_two}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(glass_single_main)}>
                {getBadge(glass_single_main)}
                {'玻璃單根主活動'}
              </Tooltip>
            }
            key='5'
          >
            <EventChooser
              event={glass_single_main}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(stainless)}>
                {getBadge(stainless)}
                {'不鏽鋼單根活動'}
              </Tooltip>
            }
            key='6'
          >
            <EventChooser
              event={stainless}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(hook_event)}>
                {getBadge(hook_event)}
                {'掛鉤買二送一'}
              </Tooltip>
            }
            key='7'
          >
            <EventChooser
              event={hook_event}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
          <TabPane
            tab={
              <Tooltip title={getTitle(nano_sponge)}>
                {getBadge(nano_sponge)}
                {'木紋便當盒送海綿'}
              </Tooltip>
            }
            key='8'
          >
            <EventChooser
              event={nano_sponge}
              corr={corr}
              change={changeEventItem}
            />
          </TabPane>
        </Tabs>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  let corr = state.ding.dingShopeeCorr
  let setuploading = state.orderProcess.events.view.loading
  const {
    hook_event,
    stainless,
    glass_single_main,
    glass_single_two_one,
    cut,
    glass_comb_get_glove,
    straw_glove_get_box,
    tau_get_tau,
    tau_get_glass,
    glass_comb_give_tau_every,
    wash_get_storage,
    wan_wan_get_two,
    tau_n_get_n,
    glass_comb_tau_and_box,
    pandora,
    nano_sponge,
    cup_get_sticker,
    cup_get_bottom_tau,
    evey_tau_get_box,
    silicon_get_cut,
    jp_mask_box_get_mask_clip,
    random_three_aroma_clips,
    eight_clips_get_oil,
    four_glasses_get_two_brushes,
    two_get_smile_box,
    three_get_smile_box,
    dia_12mm_big_glove_get_silicon_straw,
    dia_14mm_big_glove_get_silicon_straw,
    small_glove_get_silicon_straw,
    glass_comb_extra,
    no_accumulate_four_glasses_get_two_brushes,
    four_glass_get_bag,
    four_glass_get_edge_tau,
    elephant_cup_get_tulip_cup_glove,
  } = state.orderProcess.events.data
  return {
    loading: setuploading || !corr,
    hook_event,
    stainless,
    glass_single_main,
    glass_single_two_one,
    cut,
    glass_comb_get_glove,
    straw_glove_get_box,
    tau_get_tau,
    tau_get_glass,
    corr,
    glass_comb_give_tau_every,
    wash_get_storage,
    wan_wan_get_two,
    tau_n_get_n,
    glass_comb_tau_and_box,
    pandora,
    nano_sponge,
    cup_get_sticker,
    cup_get_bottom_tau,
    evey_tau_get_box,
    silicon_get_cut,
    jp_mask_box_get_mask_clip,
    random_three_aroma_clips,
    eight_clips_get_oil,
    four_glasses_get_two_brushes,
    two_get_smile_box,
    three_get_smile_box,
    dia_12mm_big_glove_get_silicon_straw,
    dia_14mm_big_glove_get_silicon_straw,
    small_glove_get_silicon_straw,
    glass_comb_extra,
    no_accumulate_four_glasses_get_two_brushes,
    four_glass_get_bag,
    four_glass_get_edge_tau,
    elephant_cup_get_tulip_cup_glove,
  }
}

export default connect(mapStateToProps, { setUpEventEdit, changeEventItem })(
  GiftEventTab
)

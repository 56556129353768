import {
  BACK_TO_GIFT_SET,
  BACK_TO_RANGE,
  CHANGE_EVENT_ITEM,
  CLEAR_GENERATE,
  DO_GENERATE_CORRESPOND,
  DO_SHIPPINGNAME_CORR,
  FAIL_DO_SHIPPINGNAME_CORR,
  FAIL_RENEW_ALL_DATA_FROM_DING,
  FAIL_REQUEST_DING_SHOPEE_CORR,
  FAIL_REQUEST_ORDERS_FROM_DING,
  FAIL_SET_UP_EVENT_EDIT,
  FAIL_UPLOAD_EXCEL_ORDERS_FROM_SHOPEE,
  GENERATE_ALL_CORRESPOND,
  GENERATE_HAS_NO_CORRESPOND,
  GIFT_SET,
  RENEW_ALL_DATA_FROM_DING,
  RENEW_GIFT_SET,
  RENEW_RANGE,
  REQUEST_DING_SHOPEE_CORR,
  REQUEST_ORDERS_FROM_DING,
  SEND_ORDER_GENERATE_DATA,
  SET_UP_EVENT_EDIT,
  SUCCESS_RENEW_ALL_DATA_FROM_DING,
  SUCCESS_REQUEST_DING_SHOPEE_CORR,
  SUCCESS_SEND_ORDER_GENERATE_DATA,
  SUCCESS_SET_UP_EVENT_EDIT,
  SUCCESS_UPLOAD_EXCEL_ORDERS_FROM_SHOPEE,
  SUCESS_DO_SHIPPINGNAME_CORR,
  SUCESS_REQUEST_ORDERS_FROM_DING,
  UPDATE_ORDER_TEXT,
  UPLOAD_EXCEL_ORDERS_FROM_SHOPEE,
} from '../actions/types'

const initialState = {
  generate: {
    view: {
      loading: false,
      current: 0,
      nextBtn: {
        disable: true,
        visible: true,
        tooltip: '請先收集資料喔',
        text: '下一步',
      },
      prevBtn: {
        disable: true,
        visible: false,
        tooltip: '',
        text: '上一步',
      },
      collect: {
        dingLoading: false,
        shopeeExcelUploading: false,
      },
      corr: {
        loading: true,
        origin: '',
        noCorr: [],
      },
      range: {
        startNum: 1,
        from: '',
        to: '',
      },
      gift: {
        hook_event: {
          checked: true,
        },
        two_get_smile_box: {
          checked: true,
        },
        three_get_smile_box: {
          checked: true,
        },
        wan_wan_get_two: {
          checked: true,
        },
        stainless: {
          checked: true,
        },
        glass_single_main: {
          checked: true,
        },
        glass_single_two_one: {
          checked: true,
        },
        cut: {
          checked: true,
        },
        silicon_get_cut: {
          checked: true,
        },
        glass_comb_extra: {
          checked: true,
        },
        elephant_cup_get_tulip_cup_glove: {
          checked: true,
        },
        glass_comb_get_glove: {
          checked: true,
          gift: '',
        },
        cup_get_sticker: {
          checked: true,
          gift: '',
        },
        glass_comb_tau_and_box: {
          checked: true,
          gift: '',
        },
        straw_glove_get_box: {
          checked: true,
          defaultType: '', // 膠囊盒 or 方盒子
          gift: '',
        },
        evey_tau_get_box: {
          checked: true,
          gift: '',
        },
        tau_get_tau: {
          checked: true,
        },
        tau_get_glass: {
          checked: true,
        },
        glass_comb_give_tau_every: {
          checked: true,
          defaultType: '',
          gift: '',
        },
        wash_get_storage: {
          checked: true,
        },
        tau_n_get_n: {
          checked: true,
        },
        pandora: {
          checked: true,
        },
        nano_sponge: {
          checked: true,
        },
        cup_get_bottom_tau: {
          checked: true,
        },
        random_three_aroma_clips: {
          checked: true,
        },
        eight_clips_get_oil: {
          checked: true,
        },
        four_glasses_get_two_brushes: {
          checked: true,
        },
        dia_12mm_big_glove_get_silicon_straw: {
          checked: true,
        },
        dia_14mm_big_glove_get_silicon_straw: {
          checked: true,
        },
        small_glove_get_silicon_straw: {
          checked: true,
        },
        no_accumulate_four_glasses_get_two_brushes: {
          checked: true,
        },
        four_glass_get_bag: {
          checked: true,
          gift: '',
        },
        four_glass_get_edge_tau: {
          checked: true,
          gift: '',
        },
      },
      check: {
        text: '',
        warning: [],
        textCorresponding: false,
        origin: '',
        orderRecordId: '',
      },
    },
    steps: [
      {
        title: '資料收集',
        status: 'process', // error, finish, process, wait
      },
      {
        title: '資料對應',
        status: 'wait',
      },
      {
        title: '訂單選取',
        status: 'wait',
      },
      {
        title: '活動贈送',
        status: 'wait',
      },
      {
        title: '庫存檢查',
        status: 'wait',
      },
    ],

    stage: 0,
    generateId: '',
    // corr: { origin: '', noCorr: [] },
    orders: [], // [{No,BuyAccount,TotalAmount,ReceiverName,ReceiverPhone,ShippingName,OrderDate}]
    giftEvents: [],
    qtyRemind: [],
  },
  correspondence: {
    view: {
      dataTableLoading: false,
    },
  },
  events: {
    view: {
      loading: false,
    },
    data: {},
  },
  dingRenewAll: {
    view: {
      loading: false,
    },
  },
}

export default function orderProcessReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case RENEW_ALL_DATA_FROM_DING:
      return {
        ...state,
        dingRenewAll: {
          ...state.dingRenewAll,
          view: {
            ...state.dingRenewAll.view,
            loading: true,
          },
        },
      }
    case SUCCESS_RENEW_ALL_DATA_FROM_DING:
    case FAIL_RENEW_ALL_DATA_FROM_DING:
      return {
        ...state,
        dingRenewAll: {
          ...state.dingRenewAll,
          view: {
            ...state.dingRenewAll.view,
            loading: false,
          },
        },
      }
    case REQUEST_DING_SHOPEE_CORR:
      return {
        ...state,
        correspondence: {
          ...state.correspondence,
          view: {
            ...state.correspondence.view,
            dataTableLoading: true,
          },
        },
      }
    case SUCCESS_REQUEST_DING_SHOPEE_CORR:
      return {
        ...state,
        correspondence: {
          ...state.correspondence,
          view: {
            ...state.correspondence.view,
            dataTableLoading: false,
          },
        },
      }
    case FAIL_REQUEST_DING_SHOPEE_CORR:
      return {
        ...state,
        correspondence: {
          ...state.correspondence,
          view: {
            ...state.correspondence.view,
            dataTableLoading: false,
          },
        },
      }
    case UPLOAD_EXCEL_ORDERS_FROM_SHOPEE:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            loading: true,
            nextBtn: {
              ...state.generate.view.nextBtn,
              disable: true,
              visible: true,
              tooltip: '請等待訂單資料收集',
              text: '下一步',
            },
            prevBtn: {
              ...state.generate.view.prevBtn,
              disable: true,
              visible: false,
              tooltip: '請等待訂單資料收集',
            },
            collect: {
              ...state.generate.view.collect,
              shopeeExcelUploading: true,
            },
          },
        },
      }
    case SUCCESS_UPLOAD_EXCEL_ORDERS_FROM_SHOPEE:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            loading: false,
            nextBtn: {
              ...state.generate.view.nextBtn,
              tooltip: '',
              visible: true,
              disable: false,
              text: '下一步',
            },
            collect: {
              ...state.generate.view.collect,
              shopeeExcelUploading: false,
            },
            corr: {
              ...state.generate.view.corr,
              origin: 'shopee',
            },
          },
          generateId: payload.id,
          steps: state.generate.steps.map((step) =>
            step.title === '資料收集' ? { ...step, status: 'finish' } : step
          ),
        },
      }
    case FAIL_UPLOAD_EXCEL_ORDERS_FROM_SHOPEE:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            loading: false,
            nextBtn: {
              ...state.generate.view.nextBtn,
              tooltip: '請重新嘗試下載資料',
              text: '下一步',
            },
            collect: {
              ...state.generate.view.collect,
              shopeeExcelUploading: false,
            },
          },
          steps: state.generate.steps.map((step) =>
            step.title === '資料收集' ? { ...step, status: 'error' } : step
          ),
        },
      }
    case REQUEST_ORDERS_FROM_DING:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            loading: true,
            nextBtn: {
              ...state.generate.view.nextBtn,
              disable: true,
              visible: true,
              tooltip: '請等待訂單資料收集',
              text: '下一步',
            },
            prevBtn: {
              ...state.generate.view.prevBtn,
              disable: true,
              visible: false,
              tooltip: '請等待訂單資料收集',
            },
            collect: {
              ...state.generate.view.collect,
              dingLoading: true,
            },
          },
        },
      }
    case SUCESS_REQUEST_ORDERS_FROM_DING:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            loading: false,
            nextBtn: {
              ...state.generate.view.nextBtn,
              tooltip: '',
              visible: true,
              disable: false,
              text: '下一步',
            },
            collect: {
              ...state.generate.view.collect,
              dingLoading: false,
            },
            corr: {
              ...state.generate.view.corr,
              origin: 'ding',
            },
          },
          generateId: payload.id,
          steps: state.generate.steps.map((step) =>
            step.title === '資料收集' ? { ...step, status: 'finish' } : step
          ),
        },
      }
    case FAIL_REQUEST_ORDERS_FROM_DING:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            loading: false,
            nextBtn: {
              ...state.generate.view.nextBtn,
              tooltip: '請重新嘗試下載資料',
              text: '下一步',
            },
            collect: {
              ...state.generate.view.collect,
              dingLoading: false,
            },
          },
          steps: state.generate.steps.map((step) =>
            step.title === '資料收集' ? { ...step, status: 'error' } : step
          ),
        },
      }
    case DO_GENERATE_CORRESPOND:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            loading: true,
            nextBtn: {
              ...state.generate.view.nextBtn,
              tooltip: '請等待資料對應',
              visible: true,
              disable: true,
              text: '下一步',
            },
            prevBtn: {
              ...state.generate.view.prevBtn,
              tooltip: '請等待資料對應',
              visible: true,
              disable: true,
            },
            current: 1,
            corr: {
              ...state.generate.view.corr,
              loading: true,
            },
          },
          steps: state.generate.steps.map((step) =>
            step.title === '資料對應' ? { ...step, status: 'process' } : step
          ),
        },
      }
    case GENERATE_ALL_CORRESPOND:
      let events = {
        hook_event: payload.giftEvents.find((ob) => ob.id === 'hook_event'),
        stainless: payload.giftEvents.find((ob) => ob.id === 'stainless'),
        glass_single_main: payload.giftEvents.find(
          (ob) => ob.id === 'glass_single_main'
        ),
        glass_single_two_one: payload.giftEvents.find(
          (ob) => ob.id === 'glass_single_two_one'
        ),
        cut: payload.giftEvents.find((ob) => ob.id === 'cut'),
        silicon_get_cut: payload.giftEvents.find(
          (ob) => ob.id === 'silicon_get_cut'
        ),
        glass_comb_extra: payload.giftEvents.find(
          (ob) => ob.id === 'glass_comb_extra'
        ),
        glass_comb_get_glove: payload.giftEvents.find(
          (ob) => ob.id === 'glass_comb_get_glove'
        ),
        glass_comb_tau_and_box: payload.giftEvents.find(
          (ob) => ob.id === 'glass_comb_tau_and_box'
        ),
        straw_glove_get_box: payload.giftEvents.find(
          (ob) => ob.id === 'straw_glove_get_box'
        ),
        evey_tau_get_box: payload.giftEvents.find(
          (ob) => ob.id === 'evey_tau_get_box'
        ),
        tau_get_tau: payload.giftEvents.find((ob) => ob.id === 'tau_get_tau'),
        tau_get_glass: payload.giftEvents.find(
          (ob) => ob.id === 'tau_get_glass'
        ),
        glass_comb_give_tau_every: payload.giftEvents.find(
          (ob) => ob.id === 'glass_comb_give_tau_every'
        ),
        wash_get_storage: payload.giftEvents.find(
          (ob) => ob.id === 'wash_get_storage'
        ),
        wan_wan_get_two: payload.giftEvents.find(
          (ob) => ob.id === 'wan_wan_get_two'
        ),
        tau_n_get_n: payload.giftEvents.find((ob) => ob.id === 'tau_n_get_n'),
        pandora: payload.giftEvents.find((ob) => ob.id === 'pandora'),
        nano_sponge: payload.giftEvents.find((ob) => ob.id === 'nano_sponge'),
        cup_get_sticker: payload.giftEvents.find(
          (ob) => ob.id === 'cup_get_sticker'
        ),
        cup_get_bottom_tau: payload.giftEvents.find(
          (ob) => ob.id === 'cup_get_bottom_tau'
        ),
        jp_mask_box_get_mask_clip: payload.giftEvents.find(
          (ob) => ob.id === 'jp_mask_box_get_mask_clip'
        ),
        random_three_aroma_clips: payload.giftEvents.find(
          (ob) => ob.id === 'random_three_aroma_clips'
        ),
        eight_clips_get_oil: payload.giftEvents.find(
          (ob) => ob.id === 'eight_clips_get_oil'
        ),
        four_glasses_get_two_brushes: payload.giftEvents.find(
          (ob) => ob.id === 'four_glasses_get_two_brushes'
        ),
        two_get_smile_box: payload.giftEvents.find(
          (ob) => ob.id === 'two_get_smile_box'
        ),
        three_get_smile_box: payload.giftEvents.find(
          (ob) => ob.id === 'three_get_smile_box'
        ),
        dia_12mm_big_glove_get_silicon_straw: payload.giftEvents.find(
          (ob) => ob.id === 'dia_12mm_big_glove_get_silicon_straw'
        ),
        dia_14mm_big_glove_get_silicon_straw: payload.giftEvents.find(
          (ob) => ob.id === 'dia_14mm_big_glove_get_silicon_straw'
        ),
        small_glove_get_silicon_straw: payload.giftEvents.find(
          (ob) => ob.id === 'small_glove_get_silicon_straw'
        ),
        no_accumulate_four_glasses_get_two_brushes: payload.giftEvents.find(
          (ob) => ob.id === 'no_accumulate_four_glasses_get_two_brushes'
        ),
        four_glass_get_bag: payload.giftEvents.find(
          (ob) => ob.id === 'four_glass_get_bag'
        ),
        four_glass_get_edge_tau: payload.giftEvents.find(
          (ob) => ob.id === 'four_glass_get_edge_tau'
        ),
        elephant_cup_get_tulip_cup_glove: payload.giftEvents.find(
          (ob) => ob.id === 'elephant_cup_get_tulip_cup_glove'
        ),
      }
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            loading: false,
            nextBtn: {
              ...state.generate.view.nextBtn,
              tooltip: payload.orders[0]
                ? ''
                : '沒有可以寫的單，確認是不是在揀貨單那邊有了，可以刪掉重寫喔。',
              visible: true,
              disable: !payload.orders[0],
              text: '下一步',
            },
            prevBtn: {
              ...state.generate.view.prevBtn,
              tooltip: '',
              visible: true,
              disable: false,
            },
            current: 2,
            corr: {
              ...state.generate.view.corr,
              loading: false,
            },
            range: {
              ...state.generate.view.range,
              from: payload.orders[0] ? payload.orders[0].No : '沒有沒寫過的單',
              to: payload.orders.slice(-1)[0]
                ? payload.orders.slice(-1)[0].No
                : '沒有沒寫過的單',
              startNum: 1,
            },
            gift: {
              ...state.generate.view.gift,
              hook_event: {
                ...state.generate.view.gift.hook_event,
                checked: events.hook_event.onGoing,
              },
              wan_wan_get_two: {
                ...state.generate.view.gift.wan_wan_get_two,
                checked: events.wan_wan_get_two.onGoing,
              },
              stainless: {
                ...state.generate.view.gift.stainless,
                checked: events.stainless.onGoing,
              },
              glass_single_main: {
                ...state.generate.view.gift.glass_single_main,
                checked: events.glass_single_main.onGoing,
              },
              glass_single_two_one: {
                ...state.generate.view.gift.glass_single_two_one,
                checked: events.glass_single_two_one.onGoing,
              },
              cut: {
                ...state.generate.view.gift.cut,
                checked: events.cut.onGoing,
              },
              silicon_get_cut: {
                ...state.generate.view.gift.silicon_get_cut,
                checked: events.silicon_get_cut.onGoing,
              },
              glass_comb_extra: {
                ...state.generate.view.gift.glass_comb_extra,
                checked: events.glass_comb_extra.onGoing,
                gift: events.glass_comb_extra.options.default,
              },
              glass_comb_get_glove: {
                ...state.generate.view.gift.glass_comb_get_glove,
                checked: events.glass_comb_get_glove.onGoing,
                gift: events.glass_comb_get_glove.options.default,
              },
              cup_get_sticker: {
                ...state.generate.view.gift.cup_get_sticker,
                checked: events.cup_get_sticker.onGoing,
                gift: events.cup_get_sticker.options.default,
              },
              glass_comb_tau_and_box: {
                ...state.generate.view.gift.glass_comb_tau_and_box,
                checked: events.glass_comb_tau_and_box.onGoing,
                gift: events.glass_comb_tau_and_box.options.default,
              },
              straw_glove_get_box: {
                ...state.generate.view.gift.straw_glove_get_box,
                checked: events.straw_glove_get_box.onGoing,
                defaultType:
                  events.straw_glove_get_box.options.default.includes('D')
                    ? '膠囊盒'
                    : '方盒子',
                gift: events.straw_glove_get_box.options.default,
              },
              evey_tau_get_box: {
                ...state.generate.view.gift.evey_tau_get_box,
                checked: events.evey_tau_get_box.onGoing,
                gift: events.evey_tau_get_box.options.default,
              },
              tau_get_tau: {
                ...state.generate.view.gift.tau_get_tau,
                checked: events.tau_get_tau.onGoing,
              },
              tau_get_glass: {
                ...state.generate.view.gift.tau_get_glass,
                checked: events.tau_get_glass.onGoing,
              },
              glass_comb_give_tau_every: {
                ...state.generate.view.gift.glass_comb_give_tau_every,
                checked: events.glass_comb_give_tau_every.onGoing,
                gift: events.glass_comb_give_tau_every.options.default,
              },
              dia_12mm_big_glove_get_silicon_straw: {
                ...state.generate.view.gift
                  .dia_12mm_big_glove_get_silicon_straw,
                checked: events.dia_12mm_big_glove_get_silicon_straw.onGoing,
              },
              dia_14mm_big_glove_get_silicon_straw: {
                ...state.generate.view.gift
                  .dia_14mm_big_glove_get_silicon_straw,
                checked: events.dia_14mm_big_glove_get_silicon_straw.onGoing,
              },
              small_glove_get_silicon_straw: {
                ...state.generate.view.gift.small_glove_get_silicon_straw,
                checked: events.small_glove_get_silicon_straw.onGoing,
              },
              wash_get_storage: {
                ...state.generate.view.gift.wash_get_storage,
                checked: events.wash_get_storage.onGoing,
              },
              tau_n_get_n: {
                ...state.generate.view.gift.tau_n_get_n,
                checked: events.tau_n_get_n.onGoing,
              },
              pandora: {
                ...state.generate.view.gift.pandora,
                checked: events.pandora.onGoing,
              },
              nano_sponge: {
                ...state.generate.view.gift.nano_sponge,
                checked: events.nano_sponge.onGoing,
              },
              cup_get_bottom_tau: {
                ...state.generate.view.gift.cup_get_bottom_tau,
                checked: events.cup_get_bottom_tau.onGoing,
              },
              jp_mask_box_get_mask_clip: {
                ...state.generate.view.gift.jp_mask_box_get_mask_clip,
                checked: events.jp_mask_box_get_mask_clip.onGoing,
              },
              random_three_aroma_clips: {
                ...state.generate.view.gift.random_three_aroma_clips,
                checked: events.random_three_aroma_clips.onGoing,
              },
              eight_clips_get_oil: {
                ...state.generate.view.gift.eight_clips_get_oil,
                checked: events.eight_clips_get_oil.onGoing,
              },
              four_glasses_get_two_brushes: {
                ...state.generate.view.gift.four_glasses_get_two_brushes,
                checked: events.four_glasses_get_two_brushes.onGoing,
              },
              two_get_smile_box: {
                ...state.generate.view.gift.two_get_smile_box,
                checked: events.two_get_smile_box.onGoing,
              },
              three_get_smile_box: {
                ...state.generate.view.gift.three_get_smile_box,
                checked: events.three_get_smile_box.onGoing,
              },
              no_accumulate_four_glasses_get_two_brushes: {
                ...state.generate.view.gift
                  .no_accumulate_four_glasses_get_two_brushes,
                checked:
                  events.no_accumulate_four_glasses_get_two_brushes.onGoing,
              },
              four_glass_get_bag: {
                ...state.generate.view.gift.four_glass_get_bag,
                checked: events.four_glass_get_bag.onGoing,
                gift: events.four_glass_get_bag.options.default,
              },
              four_glass_get_edge_tau: {
                ...state.generate.view.gift.four_glass_get_edge_tau,
                checked: events.four_glass_get_edge_tau.onGoing,
                gift: events.four_glass_get_edge_tau.options.default,
              },
              elephant_cup_get_tulip_cup_glove: {
                ...state.generate.view.gift.elephant_cup_get_tulip_cup_glove,
                checked: events.elephant_cup_get_tulip_cup_glove.onGoing,
              },
            },
          },
          steps: state.generate.steps.map((step) =>
            step.title === '資料對應'
              ? { ...step, status: 'finish' }
              : step.title === '訂單選取'
              ? { ...step, status: 'process' }
              : step
          ),
          orders: payload.orders,
          giftEvents: payload.giftEvents,
        },
      }
    case GENERATE_HAS_NO_CORRESPOND:
      let confirmText = '請確認對應之後，到上一步重新執行資料收集'
      if (payload.origin === 'ding') {
        confirmText = '請到鼎新確認對應之後，到上一步重新執行資料收集'
      } else if (payload.origin === 'shopee') {
        confirmText =
          '請到蝦皮確認「主商品貨號」、「商品選項貨號」之後，到上一步重新執行資料收集'
      }
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            loading: false,
            nextBtn: {
              ...state.generate.view.nextBtn,
              tooltip: confirmText,
              visible: true,
              disable: true,
              text: '下一步',
            },
            prevBtn: {
              ...state.generate.view.prevBtn,
              tooltip: '',
              visible: true,
              disable: false,
            },
            current: 1,
            corr: {
              ...state.generate.view.corr,
              loading: false,
              origin: payload.origin,
              noCorr: payload.noCorr,
            },
          },
          steps: state.generate.steps.map((step) =>
            step.title === '資料對應' ? { ...step, status: 'error' } : step
          ),
        },
      }
    case RENEW_RANGE:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            range: payload,
          },
        },
      }
    case GIFT_SET:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            nextBtn: {
              ...state.generate.view.nextBtn,
              tooltip: '庫存檢查',
              visible: true,
              disable: false,
              text: '下一步',
            },
            prevBtn: {
              ...state.generate.view.prevBtn,
              tooltip: '',
              visible: true,
              disable: false,
            },
            current: 3,
          },
          steps: state.generate.steps.map((step) =>
            step.title === '訂單選取'
              ? { ...step, status: 'finish' }
              : step.title === '活動贈送'
              ? { ...step, status: 'process' }
              : step
          ),
        },
      }
    case BACK_TO_RANGE:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            nextBtn: {
              ...state.generate.view.nextBtn,
              tooltip: '',
              visible: true,
              disable: false,
              text: '下一步',
            },
            prevBtn: {
              ...state.generate.view.prevBtn,
              tooltip: '',
              visible: true,
              disable: false,
            },
            current: 2,
          },
          steps: state.generate.steps.map((step) =>
            step.title === '訂單選取'
              ? { ...step, status: 'process' }
              : step.title === '活動贈送'
              ? { ...step, status: 'wait' }
              : step
          ),
        },
      }
    case RENEW_GIFT_SET:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            gift: { ...payload },
          },
        },
      }
    case SEND_ORDER_GENERATE_DATA:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            nextBtn: {
              ...state.generate.view.nextBtn,
              tooltip: '',
              visible: false,
              disable: true,
              text: '下一步',
            },
            prevBtn: {
              ...state.generate.view.prevBtn,
              tooltip: '正在檢查庫存，生成訂單',
              visible: true,
              disable: true,
            },
            current: 4,
            loading: true,
          },
          steps: state.generate.steps.map((step) =>
            step.title === '活動贈送'
              ? { ...step, status: 'finish' }
              : step.title === '庫存檢查'
              ? { ...step, status: 'process' }
              : step
          ),
        },
      }
    case SUCCESS_SEND_ORDER_GENERATE_DATA:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            nextBtn: {
              ...state.generate.view.nextBtn,
              tooltip: '',
              visible: true,
              disable: false,
              text: '重頭生成一次',
            },
            prevBtn: {
              ...state.generate.view.prevBtn,
              tooltip: '',
              visible: true,
              disable: false,
            },
            current: 4,
            loading: false,
            check: {
              ...state.generate.view.check,
              text: payload.totalText,
              warning: payload.warnings,
              orderRecordId: payload.orderRecordId,
            },
          },
          steps: state.generate.steps.map((step) =>
            step.title === '庫存檢查' ? { ...step, status: 'finish' } : step
          ),
        },
      }
    case BACK_TO_GIFT_SET:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            nextBtn: {
              ...state.generate.view.nextBtn,
              tooltip: '',
              visible: true,
              disable: false,
              text: '下一步',
            },
            prevBtn: {
              ...state.generate.view.prevBtn,
              tooltip: '',
              visible: true,
              disable: false,
            },
            current: 3,
          },
          steps: state.generate.steps.map((step) =>
            step.title === '活動贈送'
              ? { ...step, status: 'process' }
              : step.title === '庫存檢查'
              ? { ...step, status: 'wait' }
              : step
          ),
        },
      }
    case DO_SHIPPINGNAME_CORR:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            check: {
              ...state.generate.view.check,
              textCorresponding: true,
              origin: payload,
            },
          },
        },
      }
    case SUCESS_DO_SHIPPINGNAME_CORR:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            check: {
              ...state.generate.view.check,
              textCorresponding: false,
              text: payload,
              origin: '',
            },
          },
        },
      }
    case FAIL_DO_SHIPPINGNAME_CORR:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            check: {
              ...state.generate.view.check,
              textCorresponding: false,
              origin: '',
            },
          },
        },
      }
    case UPDATE_ORDER_TEXT:
      return {
        ...state,
        generate: {
          ...state.generate,
          view: {
            ...state.generate.view,
            check: {
              ...state.generate.view.check,
              text: payload,
            },
          },
        },
      }
    case CLEAR_GENERATE:
      return {
        ...state,
        generate: { ...initialState.generate },
      }
    case SET_UP_EVENT_EDIT:
      return {
        ...state,
        events: {
          ...state.events,
          view: {
            ...state.events.view,
            loading: true,
          },
        },
      }
    case SUCCESS_SET_UP_EVENT_EDIT:
      let {
        hook_event,
        stainless,
        glass_single_main,
        glass_single_two_one,
        cut,
        glass_comb_get_glove,
        straw_glove_get_box,
        tau_get_tau,
        tau_get_glass,
        glass_comb_give_tau_every,
        wash_get_storage,
        wan_wan_get_two,
        glass_comb_tau_and_box,
        tau_n_get_n,
        pandora,
        nano_sponge,
        cup_get_sticker,
        cup_get_bottom_tau,
        evey_tau_get_box,
        silicon_get_cut,
        glass_comb_extra,
        jp_mask_box_get_mask_clip,
        random_three_aroma_clips,
        eight_clips_get_oil,
        four_glasses_get_two_brushes,
        two_get_smile_box,
        three_get_smile_box,
        dia_12mm_big_glove_get_silicon_straw,
        dia_14mm_big_glove_get_silicon_straw,
        small_glove_get_silicon_straw,
        no_accumulate_four_glasses_get_two_brushes,
        four_glass_get_bag,
        four_glass_get_edge_tau,
        elephant_cup_get_tulip_cup_glove,
      } = payload
      return {
        ...state,
        events: {
          ...state.events,
          view: {
            ...state.events.view,
            loading: false,
          },
          data: {
            ...state.events.data,
            hook_event,
            stainless,
            glass_single_main,
            glass_single_two_one,
            cut,
            glass_comb_get_glove,
            straw_glove_get_box,
            tau_get_tau,
            tau_get_glass,
            glass_comb_give_tau_every,
            wash_get_storage,
            wan_wan_get_two,
            tau_n_get_n,
            glass_comb_tau_and_box,
            pandora,
            nano_sponge,
            cup_get_sticker,
            cup_get_bottom_tau,
            evey_tau_get_box,
            silicon_get_cut,
            glass_comb_extra,
            jp_mask_box_get_mask_clip,
            random_three_aroma_clips,
            eight_clips_get_oil,
            four_glasses_get_two_brushes,
            two_get_smile_box,
            three_get_smile_box,
            dia_12mm_big_glove_get_silicon_straw,
            dia_14mm_big_glove_get_silicon_straw,
            small_glove_get_silicon_straw,
            no_accumulate_four_glasses_get_two_brushes,
            four_glass_get_bag,
            four_glass_get_edge_tau,
            elephant_cup_get_tulip_cup_glove,
          },
        },
      }
    case FAIL_SET_UP_EVENT_EDIT:
      return {
        ...state,
        events: {
          ...state.events,
          view: {
            ...state.events.view,
            loading: false,
          },
        },
      }
    case CHANGE_EVENT_ITEM:
      let { eventId, newCheckTF, tag, corr_id } = payload

      let eventToModify = state.events.data[eventId]
      let defaultItems = eventToModify.items
      let categories = eventToModify.categories
      if (newCheckTF) {
        defaultItems = new Set([...eventToModify.items, ...corr_id])
        defaultItems = Array.from(defaultItems)
        if (tag !== 'default') {
          categories = categories.map((cate) => {
            if (cate.name === tag) {
              let tagItems = new Set([...cate.items, ...corr_id])
              return {
                ...cate,
                items: Array.from(tagItems),
              }
            } else {
              return cate
            }
          })
        }
      } else {
        categories = categories.map((cate) => {
          if (cate.name === tag) {
            return {
              ...cate,
              items: cate.items.filter((ob) => !corr_id.includes(ob)),
            }
          } else if (tag === 'default') {
            return {
              ...cate,
              items: cate.items.filter((ob) => !corr_id.includes(ob)),
            }
          } else {
            return cate
          }
        })
        if (tag === 'default') {
          defaultItems = eventToModify.items.filter(
            (ob) => !corr_id.includes(ob)
          )
        }
      }
      return {
        ...state,
        events: {
          ...state.events,
          data: {
            ...state.events.data,
            [eventId]: {
              ...state.events.data[eventId],
              items: defaultItems,
              categories,
            },
          },
        },
      }
    default:
      return state
  }
}
